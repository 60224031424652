import request from '@/utils/request'

/** 查询地图坐标列表 */
export function listMap(query) {
    return request({
        url: '/system/map/list',
        method: 'get',
        params:query,
        headers: { isToken: false }
    })
}
