<template>
  <div id="map-echarts-view">
    <!-- <baidu-map
      class="map"
      ak="ak"
      @ready="handler"
      :center="center"
      style="width: 100%; height: 100%"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
    > -->
    <baidu-map
      class="map"
      ak="ak"
      @ready="handler"
      :center="center"
      style="width: 100%; height: 100%"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
    >
      <bm-map-type
        :map-types="mapTypes"
        :anchor="mapTypeAnchor"
        type="BMAP_SATELLITE_MAP"
        :offset="{ width: 40, height: 15 }"
      ></bm-map-type>

      <!--2 -->
      <bm-marker
        v-for="(marker, index) of areaMarkers"
        :key="index + 'c'"
        :icon="areaIcon(marker)"
        :position="marker"
        style="z-index: 0"
      >
        <bm-label
          :content="marker.mapName"
          :offset="{ width: 10, height: -5 }"
          :label-style="{
            border: 0,
            fontSize: '10px',
            color: '#04f3ee',
            color: '#04f3ee',
          }"
        ></bm-label>
        <!-- <bm-label
          :offset="{ width: 25, height: 5 }"
          :label-style="{ border: 0, fontSize: '10px', color: '#04f3ee' }"
        ></bm-label> -->
      </bm-marker>
      <!-- 1 小区-->
      <bm-marker
        v-for="(marker, index) of markers"
        :key="index"
        :icon="icon(marker)"
        :position="marker"
        style="z-index: 999999"
        @click="markerClick(index)"
        :zIndex=99999
      >
        <bm-label
          :content="name(marker.mapName)"
          :offset="{ width: 35, height: 15 }"
          :class="{ hidden: isHidden }"
          :label-style="{ border: 0, fontSize: '10px', color: '#04f3ee'}"
        ></bm-label>
      </bm-marker>
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMapType from "vue-baidu-map/components/controls/MapType";
// import BmMarkerClusterer from  'vue-baidu-map/components/extra/MarkerClusterer'
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import BmLabel from "vue-baidu-map/components/overlays/Label";
export default {
  name: "bmMap",
  components: {
    BaiduMap,
    BmMapType,
    BmMarker,
    BmLabel,
  },
  props: {
    markers: {
      type: Array,
      default() {
        return [];
      },
    },
    areaMarkers: {
      type: Array,
      default() {
        return [];
      },
    },
    // center:{
    //   type: Object,
    //   default() {
    //     return {};
    //   }
    // },
    mapType: {
      type: Array,
      default() {
        return ["BMAP_SATELLITE_MAP"];
      },
    },
    mapTypes: {
      type: Array,
      default() {
        return ["BMAP_NORMAL_MAP", "BMAP_SATELLITE_MAP"];
      },
    },
    mapTypeAnchor: {
      type: String,
      default() {
        return "BMAP_ANCHOR_TOP_RIGHT";
      },
    },
    markerType: {
      type: String,
      default() {
        return "xqzb";
        // return "dizhi";
      },
    },
    // zoom: {
    //   type: Number,
    //   default() {
    //     return 15;
    //   },
    // },
    markerClick: {
      type: Function,
      default() {
        return () => {};
      },
    },
    refresh: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      ak: "6aa74ff1de57fea84fbb086674d08883",
      zoom: 15,
      isHidden: false,
      center: { lng: 0, lat: 0 },
      map: undefined,
      mapStyle: [
        {
          featureType: "water",
          elementType: "all",
          stylers: {
            color: "#021019",
          },
        },
        {
          featureType: "highway",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "highway",
          elementType: "geometry.stroke",
          stylers: {
            color: "#147a92",
          },
        },
        {
          featureType: "arterial",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "arterial",
          elementType: "geometry.stroke",
          stylers: {
            color: "#0b3d51",
          },
        },
        {
          featureType: "local",
          elementType: "geometry",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "land",
          elementType: "all",
          stylers: {
            color: "#12418b",
          },
        },
        {
          featureType: "railway",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "railway",
          elementType: "geometry.stroke",
          stylers: {
            color: "#08304b",
          },
        },
        {
          featureType: "subway",
          elementType: "geometry",
          stylers: {
            lightness: -70,
          },
        },
        {
          featureType: "building",
          elementType: "geometry.fill",
          stylers: {
            color: "#000000",
          },
        },
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: {
            color: "#857f7f",
          },
        },
        {
          featureType: "building",
          elementType: "geometry",
          stylers: {
            color: "#022338",
          },
        },
        {
          featureType: "green",
          elementType: "geometry",
          stylers: {
            color: "#062032",
          },
        },
        {
          featureType: "boundary",
          elementType: "all",
          stylers: {
            color: "#1e1c1c",
          },
        },
        {
          featureType: "manmade",
          elementType: "geometry",
          stylers: {
            color: "#022338",
          },
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: {
            visibility: "off",
          },
        },
        {
          featureType: "all",
          elementType: "labels",
          stylers: {
            visibility: "off",
          },
        },
      ],
    };
  },
  created() {},
  watch: {
    refresh: {
      handler: function () {
        if (this.map) {
          this.reload();
        }
      },
      immediate: true,
    },
  },

  methods: {
    handler({ map }) {
      this.map = map;
      this.reload();
      map.setMapStyle({ styleJson: this.mapStyle });
    },
    icon(item) {
      return {
        url: require("@/assets/icon/" + (item.mapIcon || this.markerType) + "-min.png"),
        size: { width: 48, height: 48 },
        opts: {
          anchor: {
            width: 16,
            height: 16,
          },
        },
      };
    },
    areaIcon() {
      return {
        url: require("@/assets/icon/mapdz-a1.png"),
        // size: { width: 48, height: 48 },
        size: { width: 8, height: 8 },
        opts: {
          anchor: {
            width: 1,
            height: 16,
          },
        },
      };
    },
    name(name) {
      return "zagt,dqfwzx,sqdgw,school,xhs,jks".indexOf(this.markerType) > -1 ? "" : name;
    },
    reload() {
      let markerList = this.markers.concat(this.areaMarkers);
      console.log("this.map.getViewport(eval(markerList));",this.map.getViewport(eval(markerList)));
      let view = this.map.getViewport(eval(markerList));
      let center = view.center;
      if (this.markerType == "sqdgw") {
        center.lat += 0.008;
        // this.zoom = 10
      }
      if (this.markerType == "dqfwzx") {
        center.lat += 0.006;
        // this.zoom = 13
      }
      if (this.markerType == "djzd") {
        center.lat += 0.006;
        center.lng -= 0.02
        // this.zoom = 13
      }
      if (this.markerType == "zagt") {
        center.lat += 0.009;
        // this.zoom = 15
        // center.lat += 0.0006;
      }

      if (this.markerType == "school") {
        center.lat += 0.009;
      }
      if (this.markerType == "xhs") {
        center.lat += 0.009;
      }
      if (this.markerType == "jks") {
        center.lat += 0.008;
      }
      if (this.markerType == "xqzb") {
        // center.lat += 0.006;
        // zoom = view.zoom;
        // zoom.zoom = 15;
        center.lat += 0.006;
      }
      this.zoom = view.zoom + 1;
      this.center = center;
    },
  },
};
</script>

<style scoped lang="scss">
#map-echarts-view {
  width: 100%;
  height: 100%;
  position: relative;
  //position: absolute;
  //top: 0;
  //left: 0;
}
.BMap_cpyCtrl {
  display: none;
}
::v-deep .anchorBL {
  display: none;
}
::v-deep .BMapLabel {
  background-color: transparent !important;
}
</style>
